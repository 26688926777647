import {FirebaseRepository} from "./service/firebase/FirebaseRepository";
import {IRepository} from "./IRepository";
import {BaseEntity} from "./BaseEntity";

export class BaseRepository<T> implements IRepository<T> {

    private _repository: IRepository<T>;

    constructor(T: typeof BaseEntity & Function) {
        this._repository = new FirebaseRepository<T>(T);
    }

    create(entity: T): Promise<T> {
        return Promise.resolve(this._repository.create(entity));
    }

    delete(id: number): Promise<void> {
        return Promise.resolve(this._repository.delete(id));
    }

    deleteAll(): Promise<void> {
        return Promise.resolve(this._repository.deleteAll());
    }

    findOne(id: number): Promise<T> {
        return Promise.resolve(this._repository.findOne(id));
    }

    getAll(): Promise<any> {
        return Promise.resolve(this._repository.getAll());
    }

    update(id: number, entity: T): Promise<T> {
        return Promise.resolve(this._repository.update(id, entity));
    }

}
