import {BaseService} from "../../../core/BaseService";
import {Page} from "./Page";
import {IService} from "../../../core/IService";
import {BaseRepository} from "../../../core/BaseRepository";
import {IRepository} from "../../../core/IRepository";
import {BaseEntity} from "../../../core/BaseEntity";

export class PageRepository extends BaseRepository<Page> implements IRepository<Page> {
    constructor(page:  typeof BaseEntity) {
        super(page);
    }
}
