import {BaseService} from "../../../core/BaseService";
import {Gallery} from "./Gallery";
import {IService} from "../../../core/IService";
import {BaseRepository} from "../../../core/BaseRepository";
import {IRepository} from "../../../core/IRepository";
import {BaseEntity} from "../../../core/BaseEntity";

export class GalleryRepository extends BaseRepository<Gallery> implements IRepository<Gallery> {
    constructor(page:  typeof BaseEntity) {
        super(page);
    }
}
