import React from "react";
import "../assets/css/style.scss"
import {Homepage} from "./page/Homepage/Homepage";
import Page from "./page";
import Gallery from "./page/Gallery";

export function Site() {

    return (
        <div>
            <Homepage/>
            <Page/>
            <Gallery />
        </div>
    );
}
