let config, firebaseConfig = {};
const url = 'http://localhost:9090';

const devConfig = {
  baseUrl: `${url}/api/v1/`,
  serverName: url,
  firebaseConfig: {
    apiKey: "AIzaSyBKlkY957F9u5I48tZgIeHC23001Pq0LMI",
    authDomain: "sulavnamenp.firebaseapp.com",
    databaseURL: "https://sulavnamenp-default-rtdb.firebaseio.com",
    projectId: "sulavnamenp",
    storageBucket: "sulavnamenp.appspot.com",
    messagingSenderId: "732395201097",
    appId: "1:732395201097:web:df977ad595312636b1d598",
    measurementId: "G-8DPXJ24K93"
  }
};

const prodConfig = {
  baseUrl: `${window.location.origin}/api/v1/`,
  serverName: `${window.location.origin}`,
  firebaseConfig: {
    apiKey: "AIzaSyBKlkY957F9u5I48tZgIeHC23001Pq0LMI",
    authDomain: "sulavnamenp.firebaseapp.com",
    databaseURL: "https://sulavnamenp-default-rtdb.firebaseio.com",
    projectId: "sulavnamenp",
    storageBucket: "sulavnamenp.appspot.com",
    messagingSenderId: "732395201097",
    appId: "1:732395201097:web:df977ad595312636b1d598",
    measurementId: "G-8DPXJ24K93"
  }
};

if (process.env.NODE_ENV === 'production') {
  config = prodConfig;
  firebaseConfig = prodConfig.firebaseConfig;
} else {
  config = devConfig;
  firebaseConfig = devConfig.firebaseConfig;
}
export default {config, firebaseConfig};
