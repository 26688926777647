import {BaseService} from "../../../core/BaseService";
import {Gallery} from "./Gallery";
import {IService} from "../../../core/IService";
import {BaseRepository} from "../../../core/BaseRepository";
import {GalleryRepository} from "./GalleryRepository";

export class GalleryService extends BaseService<Gallery> implements IService<Gallery> {

  constructor() {
    const repository = new GalleryRepository(Gallery);
    super(repository);
  }
}
