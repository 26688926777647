import {IRepository} from "../../IRepository";
import {db} from "./index";
import {Database, push, ref, set} from "firebase/database";
import {BaseEntity} from "../../BaseEntity";

export class FirebaseRepository<T> implements IRepository<T> {

  private modelName: string;
  private db: Database;

  constructor(T: typeof BaseEntity & Function) {
    this.db = db;
    this.modelName = T.name;
    console.log(this.db, 'this.db');
    console.log(this.modelName, 'this.modelName');
  }

  create(entity: T): Promise<any> {
    return Promise.resolve(set(ref(db, this.modelName + '/'), entity))
    .then((res) => {
      console.log(res)
    }).catch(error => {
      console.log(error);
    });
  }

  delete(id: number): Promise<void> {
    return Promise.resolve(undefined);
  }

  deleteAll(): Promise<void> {
    return Promise.resolve(set(ref(db, '/' + this.modelName), null))
    .then((res) => {
      console.log(res)
    }).catch(error => {
      console.log(error);
    });
  }

  findOne(id: number): Promise<any> {
    return Promise.resolve(undefined);
  }

  getAll(): Promise<T[]> {
    return Promise.resolve([]);
  }

  update(id: number, entity: T): Promise<any> {
    return Promise.resolve(undefined);
  }
}
