import {PageService} from "../../../../../services/page/PageService";
import {useEffect} from "react";
import {Page} from "../../../../../services/page/Page";

export function ViewPage() {
  const service = new PageService();

  useEffect(() => {
    try {
      const entity = new Page();
      entity.id = 12312312;
      // const response = service.deleteAll();
      const response = service.create(entity);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }, []);
}
