import {IRepository} from "./IRepository";
import {IService} from "./IService";

export class BaseService<T> implements IService<T> {
  private _repository: IRepository<T>;

  constructor(_repository: IRepository<T>) {
    this._repository = _repository;
  }

  public async getAll(): Promise<T[]> {
    return await this._repository.getAll();
  }

  public async getById(id: number): Promise<T> {
    return await this._repository.findOne(id);
  }

  public async create(entity: T): Promise<T> {
    return await this._repository.create(entity);
  }

  public async update(id: number, entity: T): Promise<any> {
    return await this._repository.update(id, entity);
  }

  public async delete(id: number): Promise<void> {
    await this._repository.delete(id);
  }

  public async deleteAll(): Promise<void> {
    await this._repository.deleteAll();
  }
}
