import React from "react";
import {CDropdownMenu} from "@coreui/react";

export function Homepage() {
    return (
        <div className="row wrapper">
            <div className="container">
                <div className="card profile-card">
                    <div className="row">
                        <div className="col-5 col-md-5 col-xl-4 img-thumbnail-wrapper">
                            <img className="img-thumbnail rounded-circle "
                                 src="https://secure.gravatar.com/avatar/107f91fe2a1179e549e0a9a50c939f68?s=256"
                                 alt="Sulav Shrestha"/>
                        </div>
                        <div className="card-body col-md-7 col-xl-8">
                            <h1 className="card-title">Sulav Shrestha</h1>

                            <div className="social-links">
                                <a href="https://facebook.com/iamsulavshrestha" target="_blank">
                                    <img
                                        src="https://www.vectorlogo.zone/logos/facebook/facebook-icon.svg"
                                        alt="Facebook"/></a>
                                <a href="https://twitter.com/iamsulavshresth" target="_blank">
                                    <img
                                        src="https://www.vectorlogo.zone/logos/twitter/twitter-icon.svg"
                                        alt="Twitter"/></a>
                                <a href="https://linkedin.com/in/iamsulavshrestha" target="_blank">
                                    <img
                                        src="https://www.vectorlogo.zone/logos/linkedin/linkedin-icon.svg"
                                        alt="Linkedin"/>
                                </a>
                                <a href="https://github.com/iamsulavshrestha" target="_blank">
                                    <img
                                        src="https://www.vectorlogo.zone/logos/github/github-icon.svg"
                                        alt="Github"/>
                                </a>
                                <a href="https://wordpress.com/" target="_blank">
                                    <img
                                        src="https://www.vectorlogo.zone/logos/wordpress/wordpress-icon.svg"
                                        alt="WordPress"/>
                                </a>
                            </div>
                            <div className="weblink">
                                <div className="other-links">
                                        <span className="p-1">
                                        <a href="https://resume.sulav.name.np"
                                           className="btn-outline-dark btn-sm btn mb-2 ">CV/Resume</a>
                                            </span>
                                        {/*<span className="p-1">*/}
                                        {/*<a href="https://blog.sulav.name.np"*/}
                                        {/*   className="btn-outline-dark btn-sm btn mb-2 ">Blog</a>*/}
                                        {/*    </span>*/}
                                    <span className="p-1">
                                        <a href="https://code-blog.sulav.name.np"
                                           className="btn-outline-dark btn-sm btn mb-2">Coding
                                            Blog</a></span>
                                    {/*<span className="p-1"><a href="https://tech-blog.sulav.name.np"*/}
                                    {/*                         className="btn-outline-dark btn-sm btn mb-2">Tech*/}
                                    {/*        News Blog</a></span>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
