import {BaseService} from "../../../core/BaseService";
import {Page} from "./Page";
import {IService} from "../../../core/IService";
import {BaseRepository} from "../../../core/BaseRepository";
import {PageRepository} from "./PageRepository";

export class PageService extends BaseService<Page> implements IService<Page> {

  constructor() {
    const repository = new PageRepository(Page);
    super(repository);
  }
}
